export const experienceData = [

    {
        id: 1,
        company: 'Aquila360 Private Limited',
        jobtitle: 'Senior Software Engineer',
        startYear: 'Sept 2023',
        endYear: 'Present'
    },

    {
        id: 2,
        company: 'Social27 Inc',
        jobtitle: 'Senior Software Engineer',
        startYear: 'March 2022',
        endYear: 'Present'
    },
    {
        id: 3,
        company: 'Aquila360 Private Limited',
        jobtitle: 'Software Engineer',
        startYear: 'Sept 2022',
        endYear: 'Aug 2023'
    },
    {
        id: 4,
        company: 'Aquila360 Private Limited',
        jobtitle: 'Associate Software Engineer',
        startYear: 'Sept 2021',
        endYear: 'Aug 2022'
    },


]